<!-- DateType Select -->
  <div class="filter-section even-wrapper">
    <div class="ng-section summary-section hq-root1-name select-sale-date">
      <b>Date Type</b>
      <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
        [searchable]="false"
        [virtualScroll]="true"
        [(ngModel)]="lstOption_Selected_DateType"
        [multiple]="false"
        [closeOnSelect]="false"
        (blur)="onDateTypeSave(true)"
        [clearable]="lstOption_Selected_SalesDate$.value.length > 1">
            <ng-option *ngFor="let item of lstOption_DateType" [value]="item.value">
                <input type="checkbox" class="cb-multi-select"/>{{item.value}}
            </ng-option>
            <ng-template ng-footer-tmp>
                <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected_DateType.length == 0"
                (click)="onDateTypeSave(false); ngFilterDateType.close(); onTrackDropdownAggregate('Date Type', [lstOption_Selected_DateType])">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
                <ng-container *ngFor="let item of items; index as i">
                    <ng-container *ngIf="i==0">
                        <div class="text-over-dropdown">{{item}}</div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="items.length > 1">
                    ...
                </ng-container>
            </ng-template>
        </ng-select>
    </div>

    <!-- Single SalesDate Select-->
    <single-dropdown
      *ngIf="isSingleMonthDropdown"
      title="Month"
      [items]="lstOption_SalesDate$.value"
      (change)="onSingSalesDateSaveItemsChange($event)"></single-dropdown>

    <!-- Calendar -->
    <div [class]="(isOnlyView ? 'disabled ' : 'enabled') + ' ' + 'ng-section summary-section salesdate-section js-salesdate-section date-range-input'" *ngIf="isCalendar && dailySalesDateRange.ready">
      <date-range-picker className="form-control input-date-range"
        [from] = "dailySalesDateRange.fromDate"
        [to] = "dailySalesDateRange.toDate"
        [maxDate] = "dailySalesDateRange.maxDate"
        [minDate] = "dailySalesDateRange.minDate"
        (dateRangeSelection)="onDateSelect($event)" pickerName="Sales Date" id="sku-deepdive-date-picker">
      </date-range-picker>
    </div>

    <!-- From/To Select -->
    <div *ngIf="isFromToDropdown" [class]="(isOnlyView ? 'disabled' : 'enabled') + ' ' + 'ng-section summary-section hq-root1-name select-sale-date'">
      <b>From</b>
      <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
        [searchable]="false"
        [virtualScroll]="true"
        [(ngModel)]="lstOption_Selected_From"
        [multiple]="false"
        [closeOnSelect]="false"
        (blur)="onFromToDropdownSave(true, 'from')"
        [clearable]="lstOption_Selected_SalesDate$.value.length > 1">
            <ng-option *ngFor="let item of lstOption_From" [value]="item.value">
                <input type="checkbox" class="cb-multi-select"/>{{item.value}}
            </ng-option>
            <ng-template ng-footer-tmp>
                <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected_From.length == 0 || isOnlyView"
                (click)="onFromToDropdownSave(false, 'from');ngFilterDateType.close(); onTrackDropdownAggregate('From', [lstOption_Selected_From])">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
                <ng-container *ngFor="let item of items; index as i">
                    <ng-container *ngIf="i==0">
                        <div class="text-over-dropdown">{{item}}</div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="items.length > 1">
                    ...
                </ng-container>
            </ng-template>
        </ng-select>
    </div>

    <div *ngIf="isFromToDropdown" [class]="(isOnlyView ? 'disabled' : 'enable') + ' ' + 'ng-section summary-section hq-root1-name select-sale-date'">
      <b>To</b>
      <ng-select #ngFilterDateType class="form-control filter-by-category sense-single-dropdown"
        [searchable]="false"
        [virtualScroll]="true"
        [(ngModel)]="lstOption_Selected_To"
        [multiple]="false"
        [closeOnSelect]="false"
        (blur)="onFromToDropdownSave(true, 'to')"
        [clearable]="lstOption_Selected_SalesDate$.value.length > 1">
            <ng-option *ngFor="let item of lstOption_To" [value]="item.value">
                <input type="checkbox" class="cb-multi-select"/>{{item.value}}
            </ng-option>
            <ng-template ng-footer-tmp>
                <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected_To.length == 0 || isOnlyView"
                (click)="onFromToDropdownSave(false, 'to');ngFilterDateType.close(); onTrackDropdownAggregate('To', [lstOption_Selected_To])">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
                <ng-container *ngFor="let item of items; index as i">
                    <ng-container *ngIf="i==0">
                        <div class="text-over-dropdown">{{item}}</div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="items.length > 1">
                    ...
                </ng-container>
            </ng-template>
        </ng-select>
    </div>
</div>
